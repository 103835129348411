// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-categories-dato-cms-tag-slug-js": () => import("./../../../src/pages/blog/categories/{datoCmsTag.slug}.js" /* webpackChunkName: "component---src-pages-blog-categories-dato-cms-tag-slug-js" */),
  "component---src-pages-blog-dato-cms-article-slug-js": () => import("./../../../src/pages/blog/{datoCmsArticle.slug}.js" /* webpackChunkName: "component---src-pages-blog-dato-cms-article-slug-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-competences-index-js": () => import("./../../../src/pages/competences/index.js" /* webpackChunkName: "component---src-pages-competences-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

